import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './MorganaCore/Modules/Header/WidgetCore';
import Hero from './MorganaCore/Modules/Hero/WidgetCore';
import About from './MorganaCore/Modules/About/WidgetCore';
import Services from './MorganaCore/Modules/Services/WidgetCore';
import Reviews from './MorganaCore/Modules/Reviews/WidgetCore';
import Footer from './MorganaCore/Modules/Footer/WidgetCore';
import Coaching from './MorganaCore/Modules/Coaching/WidgetCore';
import Parenting from './MorganaCore/Modules/Parenting/WidgetCore';
import MeetingData from './MorganaCore/Modules/MeetingData/WidgetCore';
import Form from './MorganaCore/Modules/Form/WidgetCore';
import Location from './MorganaCore/Modules/Location/WidgetCore';

import { Theme } from './MorganaCore/theme';
import Button_1 from './MorganaCore/Atoms/Button_1';
import { useEffect } from 'react';

const customTheme = new Theme();
customTheme.initFonts(`'DM Serif Display', serif`, `'Ubuntu', sans-serif`);
customTheme.initColors('#8abcd7', '#ecf8ff', '#ffffff', '#13122F');

const reuseItems = {
  button_1: Button_1,
};

let images = [
  '/images/logo-mindcon.png',
  '/images/hero-primary.png',
  '/images/image-back-dots.png',
  '/images/loader.png',
  '/images/african-american-business-woman.png',
  '/images/section-back-dots.png',
  '/images/mental-health 1.png',
  '/images/family 1.png',
  'images/chichi.png',
  'images/rita.jpg',
  'images/gloria.jpg',
  'images/coaching.png',
  'images/consiliere.png',
];

const Home = () => {
  useEffect(()=>{
    window.document.title = "Mindcon - Acasă"
  },[])
  return (
    <>
      <Hero
        themeParam={customTheme}
        titleParam="Coaching și Consiliere
Parentală"
        textParam=" "
        imageParam={{
          primary: images[1],
          background: images[2],
          loader: images[3],
        }}
        buttonParam={{
          item: reuseItems.button_1,
          props: {
            textParam: 'Rezervă',
            customStyle: {
              backgroundColor: customTheme.colors.color_10,
              padding: '2rem 3rem',
              fontSize: '1.8rem',
              fontWeight: '600',
              borderRadius: '2rem',
              color: customTheme.colors.color_60,
              margin: '4rem 0',
              width: '20rem',
            },
            onClick:()=>{window.location = 'https://calendly.com/cezaraboangiu/60min'}
          },
        }}
      />
      <About
        themeParam={customTheme}
        titleParam="Eu sunt Cezara"
        textParam={[
          'În viața de zi cu zi, îndeplinesc diferite roluri și îmi place să ajut oamenii în diverse moduri. Intenția mea este de a fi partener, ascultător și ghid pentru cei care caută să  se dezvolte, oferindu-le un spațiu sigur și încurajator pentru creștere și evoluție. ',
          'În calitate de consilier parental, intenția mea este să ofer un spațiu sigur și empatic pentru părinți, astfel încât să se simtă susținuți în procesul de creștere a copiilor lor. Voi fi lângă tine în procesul de dezvoltare a abilităților de parenting, oferind sfaturi personalizate și tehnici practice pentru a-i ajuta pe părinți să își întărească relația cu copiii lor și să își construiască o familie armonioasă și echilibrată.'
        ]}
        imageParam={{
          primary: images[4],
          background: images[5],
        }}
      />
      <Services
        themeParam={customTheme}
        titleParam="Cu ce te pot ajuta"
        textParam={<><p><b>Hai sa ne cunoastem!</b> </p><p>Rezervă o sesiune de 30 min gratuită!</p></>}
        cardsParam={[
          {
            icon: images[6],
            title: 'Coaching',
            route: '/coaching',
            text: `Ca expert în resurse umane cu o experiență de 7 ani, ofer servicii de Career Coaching și pregătire pentru interviuri. 
            Mă adresez celor care își doresc să își dezvolte cariera și să își îmbunătățească abilitățile, oferindu-le o abordare prietenoasă, dar structurată, fiind ghid prin procesul de identificare a obiectivelor de carieră, dezvoltare a planurilor de acțiune și pregătire pentru interviuri.
            `,
          },
          {
            icon: images[7],
            title: 'Consiliere parentală',
            route: '/parenting',
            text: 'Indiferent de problemele cu care vă confruntați ca părinte, sunt aici pentru a vă ajuta să găsiți cele mai bune soluții. Ofer sprijin și consiliere pentru o gamă largă de probleme, inclusiv gestionarea comportamentului dificil al copilului, comunicarea eficientă în familie, relația părinte-copil, separarea și divorțul, tulburările de alimentație și anxietatea copilului, și multe altele. ',
          },
        ]}
        buttonParam={{
          item: reuseItems.button_1,
          props: {
            textParam: 'Mai multe',
            customStyle: {
              backgroundColor: customTheme.colors.color_60,
              padding: '1.5rem 3rem',
              fontSize: '1.4rem',
              fontWeight: '600',
              borderRadius: '1.5rem',
              color: customTheme.colors.color_10,
              margin: '0',
              width: 'max-content',
            },
          },
        }}
        imageParam={{ background: images[5] }}
      />
      <Reviews
        themeParam={customTheme}
        titleParam="Clienți"
        cardsParam={[
          {
            image: images[8],
            name: 'Anca T',
            country: 'Franța ',
            text: `Empatie, deschidere, ascultare fără sa te simți judecată,  o buna observatoare, sunt toate calități cu care Cezara m-a făcut sa ies din zona de confort și sa pun în mișcare "rotilele" în ce privește viata profesională. De asemenea fiind din diaspora, e plăcut sa găsești pe cineva, care vorbește limba ta , iar serviciile oferite de Cezara online  în asemenea conjuncturi, sunt benefice și accesibile.
            O recomand cu încredere!`,
          },
          // {
          //   image: images[9],
          //   name: 'Diana',
          //   country: 'Iași, România',
          //   text: 'A fost o experiență plăcută cu ajutorul căreia am învățat să imi controlez emoțiile și fricile.',
          // },
          // {
          //   image: images[10],
          //   name: 'Alexandru',
          //   country: 'Cluj, România',
          //   text: 'A fost o experiență plăcută cu ajutorul căreia am învățat să imi controlez emoțiile și fricile.',
          // },
        ]}
      />
    </>
  );
};

const CoachingServices = () => {
  useEffect(()=>{
    window.document.title = "Mindcon - Coaching"
    window.scrollTo({ top: -20, behavior: 'smooth' });
  })
  return (
    <>
      <Coaching
        themeParam={customTheme}
        titleParam="Coaching"
        imageParam={{ primary: images[11], background: images[5] }}
        textParams={[
          'Prin coaching poți obține claritate, structura, poti transforma mesajele interioare limitative in unele constructive și ghici ce... ai făcut deja primul pas.',
          'Mă adresez celor care își doresc să își dezvolte cariera și să își îmbunătățească abilitățile, oferindu-le o abordare prietenoasă, dar structurată, fiind ghid prin procesul de identificare a obiectivelor de carieră, dezvoltare a planurilor de acțiune și pregătire pentru interviuri.',
          'Indiferent de stadiul carierei dvs., vom lucra împreună pentru a identifica punctele dvs. forte, valori și interese, pentru a le asocia cu oportunitățile de carieră existente. Vom dezvolta planuri concrete de acțiune, cu pași clari și realizabili, care să vă ajute să ajungeți la următorul nivel în cariera dvs.',
          'În plus, vă pot oferi pregătire intensivă pentru interviuri, astfel încât să vă prezentați în cel mai bun mod posibil și să obțineți job-ul dorit. Vom discuta cele mai frecvente întrebări puse în interviuri și vom lucra la răspunsuri eficiente și autentice, care să vă pună în valoare abilitățile și experiența.'
        ]}
      />
      <MeetingData
        themeParam={customTheme}
        buttonParam={{
          item: reuseItems.button_1,
          props: {
            textParam: 'Rezervă acum',
            customStyle: {
              backgroundColor: customTheme.colors.color_60,
              padding: '1.5rem 4rem',
              fontSize: '1.6rem',
              fontWeight: '600',
              borderRadius: '1.5rem',
              color: customTheme.colors.color_10,
              margin: '10rem 0 0 0',
              width: 'max-content',
            },
            onClick: ()=>{
              window.location = 'https://calendly.com/cezaraboangiu/60min'
            }
          },
        }}
        infoParams={[
          {
            icon: '/images/Vector (4).png',
            title: 'Interval',
            text: 'Flexibil',
          },
          {
            icon: '/images/Vector (5).png',
            title: 'Durata',
            text: '1 oră',
          },
          {
            icon: '/images/Vector (6).png',
            title: 'Meet',
            text: '100% online',
          },
          // {
          //   icon: '/images/Vector (7).png',
          //   title: 'Price',
          //   text: 'Free',
          // },
        ]}
      />
    </>
  );
};

const ParentingServices = () => {
  useEffect(()=>{
    window.document.title = "Mindcon - Parenting"
    window.scrollTo({ top: -20, behavior: 'smooth' });
  })


  return (
    <>
      <Parenting
        themeParam={customTheme}
        titleParam="Consiliere parentală"
        imageParam={{ primary: images[12], background: images[5] }}
        textParams={[
          'Indiferent de problemele cu care vă confruntați ca părinte, sunt aici pentru a vă ajuta să găsiți cele mai bune soluții. Ofer sprijin și consiliere pentru o gamă largă de probleme, inclusiv gestionarea comportamentului dificil al copilului, comunicarea eficientă în familie, relația părinte-copil, separarea și divorțul, tulburările de alimentație și anxietatea copilului, și multe altele. ',
          'Cu ajutorul meu, veți dezvolta abilități și tehnici utile, care vă vor ajuta să fiți un părinte mai încrezător și mai bine pregătit să gestionați provocările vieții de familie.',
          'La baza serviciilor mele de consiliere parentală se află abordarea personalizată pe baza metodelor  și instrumentelor testate și validate de cei mai buni specialiști în terapia de familie și cuplu din România. Aceste metode și instrumente au fost dezvoltate de experți recunoscuți în domeniu și sunt adaptate pentru a satisface nevoile și situația unică a fiecărui client. Nu exista soluții universale, ci soluții adecvate fiecărei familii.',
          'Vă ofer o abordare personalizată, care vă va ajuta să vă dezvoltați abilitățile de părinte și să creați o relație puternică și sănătoasă cu copilul dvs. ',
          'Sunt aici pentru a vă ajuta să găsiți cele mai bune soluții pentru dvs. și familia dvs.'
        ]}
      />
      <MeetingData
        themeParam={customTheme}
        buttonParam={{
          item: reuseItems.button_1,
          props: {
            textParam: 'Rezervă acum',
            customStyle: {
              backgroundColor: customTheme.colors.color_60,
              padding: '1.5rem 4rem',
              fontSize: '1.6rem',
              fontWeight: '600',
              borderRadius: '1.5rem',
              color: customTheme.colors.color_10,
              margin: '10rem 0 0 0',
              width: 'max-content',
            },
            onClick: ()=>{
              window.location = 'https://calendly.com/cezaraboangiu/60min'
            }
          },
        }}
        infoParams={[
          {
            icon: '/images/Vector (4).png',
            title: 'Interval',
            text: 'Flexibil',
          },
          {
            icon: '/images/Vector (5).png',
            title: 'Durata',
            text: '1 oră',
          },
          {
            icon: '/images/Vector (6).png',
            title: 'Meet',
            text: '100% online',
          },
          // {
          //   icon: '/images/Vector (7).png',
          //   title: 'Price',
          //   text: 'Free',
          // },
        ]}
      />
    </>
  );
};

const ContactPage = () => {
  useEffect(()=>{
    window.document.title = "Mindcon - Contact"
    window.scrollTo({ top: 0, behavior: 'smooth' });
  })
  return (
    <>
      <Form
        themeParam={customTheme}
        titleParam="Contactează-mă"
        textParam="Completează-ți datele și te voi contacta in cel mai scurt timp posibil."
        // inputParams={[
        //   {
        //     element: 'input',
        //     id: 'name',
        //     type: 'text',
        //     placeholder: 'Nume',
        //     errorText: 'Câmpul este obligatoriu',
        //   },
        //   {
        //     element: 'input',
        //     id: 'email',
        //     type: 'email',
        //     placeholder: 'Adresă de email',
        //     errorText: 'Adresa introdusă nu este validă',
        //   },
        //   {
        //     element: 'textarea',
        //     id: 'message',
        //     type: 'text',
        //     placeholder: 'Mesaj*',
        //     errorText: 'Câmpul este obligatoriu',
        //   },
        // ]}
        buttonParam={{
          item: reuseItems.button_1,
          props: {
            textParam: 'Trimite',
            customStyle: {
              backgroundColor: customTheme.colors.color_60,
              padding: '1.5rem 0',
              fontSize: '1.8rem',
              fontWeight: '600',
              borderRadius: '2rem',
              color: customTheme.colors.color_10,
              margin: '0',
              width: '100%',
            },
          },
        }}
      />
      <Location
        themeParam={customTheme}
        cardParams={[
          {
            title: 'Contact',
            content: [
              {
                icon: '/images/Vector (8).png',
                text: 'cezaraboangiu6@gmail.com',
              },
              {
                icon: '/images/Vector (10).png',
                text: '+40 765 004 992',
              },
            ],
          },
          {
            title: 'Locație',
            content: [
              {
                icon: '/images/Vector (9).png',
                text: 'Cluj, România',
              },
            ],
          },
        ]}
      />
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Header
        themeParam={customTheme}
        logoImgParam={images[0]}
        linksParam={[
          {
            title: 'Acasă',
            href: '/',
          },
          {
            title: 'Despre',
            href: '/#despre',
          },
          {
            title: 'Servicii',
            href: '/#servicii',
          },
          {
            title: 'Contactează-mă',
            href: '/contact',
          },
        ]}
        buttonParam={{
          item: reuseItems.button_1,
          props: {
            textParam: 'Contactează-mă',
            customStyle: {
              backgroundColor: 'transparent',
              padding: '2rem 3rem',
              fontSizeParam: '1.8rem',
              fontWeight: '600',
              borderRadius: '2rem',
              border: `2px solid ${customTheme.colors.color_10}`,
              color: customTheme.colors.color_10,
            },
          },
        }}
      />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/coaching" element={<CoachingServices />} />
        <Route exact path="/parenting" element={<ParentingServices />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/privacy" element={<p style={{height: '50vh', padding: '20px'}}>Pagina in constructie ...</p>} />

      </Routes>
      <Footer
        themeParam={customTheme}
        imageParam={images[0]}
        iconsParam={[
          {
            label: 'Instagram',
            href: 'https://instagram.com/cezaraboangiu?igshid=YmMyMTA2M2Y=',
            img: '/images/Vector (1).png',
          },
          {
            label: 'Facebook',
            href: 'https://www.facebook.com/mindconro',
            img: '/images/Vector (2).png',
          },
          {
            label: 'LinkedIn',
            href: 'https://www.linkedin.com/in/cezara-boangiu-929316144',
            img: '/images/Vector (3).png',
          },
        ]}
        addressParam="Cluj, România"
        linksParam={[
          {
            title: 'Acasă',
            href: '/#',
          },
          {
            title: 'Despre',
            href: '/#despre',
          },
          {
            title: 'Servicii',
            href: '/#servicii',
          },
          {
            title: 'Contact',
            href: '/contact',
          },
          {
            title: 'Privacy policy',
            href: '/privacy',
          },
        ]}
        copyrightParam="© MindCon. Toate drepturile rezervate."
        textParam="Conectează-te cu mine"
      />
    </BrowserRouter>
  );
}

export default App;
